import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick';
import Layout from '../../components/layout'
import GridRow from '../../components/_grid-row'


import pageStyles from '../../assets/scss/class-internal.module.scss'
import Made2moveImg from '../../images/grey-placeholder.png';

import ff from '../../images/icons/fitness.svg';
import pilates from '../../images/icons/pilates.svg';
import playGroundYoga from '../../images/icons/playground-yoga.svg';
import zumba from '../../images/icons/zumba.svg';
import boxing from '../../images/icons/boxing.svg';
import yoga from '../../images/icons/yoga.svg';
import dumbbell from '../../images/icons/dumbbell.svg';
import speedDrills from '../../images/icons/workout-2.svg';
import cardio from '../../images/icons/cardio.svg';
import funkFlow from '../../images/icons/funk-flow.svg';

export default function MasyafFitnessFiesta() {
    const sliderImages = [
        Made2moveImg,
        Made2moveImg,
        Made2moveImg,
        Made2moveImg,
        Made2moveImg,
        Made2moveImg
    ];

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.05,
                    slidesToScroll: 2.05,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.02,
                    slidesToScroll: 1.02,
                    infinite: false,
                    dots: true
                }
            },
        ]
    };

    return (
        <React.Fragment>
            <Layout pageName="Events" backLink="/events">
                <div className={["class-internal", pageStyles.wrapper].join(" ")}>
                    <Container maxWidth="xl">
                        <GridRow>
                            {/* Slider section */}
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12}>
                                    {sliderImages?
                                    <Slider  {...settings}>
                                        {
                                            sliderImages.map(img => {
                                                return (
                                                    <div>
                                                        <img src={img}/>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider>
                                    :
                                    ""

                                    }
                                </Grid>
                                {/* Content section */}
                                <Grid item xs={12} lg={8}>
                                    <div className={pageStyles.header}>
                                        <h2 className="h2">Masyaf Fitness Fiesta</h2>
                                    </div>
                                    <div className={pageStyles.desc}>
                                        <p>
                                            On August 20, the shores of Masyaf Ras Alhekma will host Sahel's biggest fitness fiesta showcasing the best coaches, fitness programs and an adrenaline infused afternoon! Get ready to sweat it with intention. 
                                            <br /><br />
                                            To inquire or book, please send an email to info@beachmonkeyz.com.
                                        </p>
                                    </div>
                                    {/* <Grid item xs={12}>
                                        <div className={pageStyles.header}>
                                            <h2 className="h2">Classes include</h2>
                                        </div>
                                        <div className={pageStyles.eventClasses}>
                                            <div className={pageStyles.eventClass}>
                                                <img src={ff} />
                                                <h3>Fitness Fundamentals</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={pilates} />
                                                <h3>Pilates</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={playGroundYoga} />
                                                <h3>Playground Yoga</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={zumba} />
                                                <h3>Zumba</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={boxing} />
                                                <h3>Boxing</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={yoga} />
                                                <h3>Vinyasa Yoga</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={dumbbell} />
                                                <h3>Hot Iron</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={speedDrills} />
                                                <h3>Speed Drills</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={cardio} />
                                                <h3>Cardio Core</h3>
                                            </div>
                                            <div className={pageStyles.eventClass}>
                                                <img src={funkFlow} />
                                                <h3>Funk Flow</h3>
                                            </div>
                                        </div>
                                    </Grid>  */}
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className={pageStyles.reserveButton}>
                                            <a href="mailto:info@beachmonkeyz.com" className={pageStyles.emailLink}>
                                                Send us an e-mail
                                            </a>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>
            </Layout>
        </React.Fragment>
    )
}
